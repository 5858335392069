import React from 'react'

function SignUpparagraph({ textColorClass, paragraphText }) {
  return (
    <p
      className={`signParagraph--${
        textColorClass ? textColorClass : 'white'
      } signParagraph`}
    >
      {paragraphText}
    </p>
  )
}

export default SignUpparagraph
