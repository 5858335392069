import React from 'react'

function PrimaryHeading({ headingText, customClass }) {
  return (
    <h1 className={`primaryHeading ${customClass ? customClass : ''}`}>
      {headingText}
    </h1>
  )
}

export default PrimaryHeading
