import React, { useState } from 'react'
import mailIcon from './mailIcon.svg'
import SignUpheading from '../../shared/Subscription/SignUpheading'
import SignUpparagraph from '../../shared/Subscription/SignUpparagraph'
import { Container } from 'react-grid-system'
import swal from 'sweetalert'

const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

function SubscribeSection({
  iconname,
  title = 'no title',
  description = 'no description',
  secondaryButton = { buttonLink: '/', buttonText: 'no text' }
}) {
  const [subscribed, setSubscribed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const hmm = () => {
    setLoading(false)
    setError(true)
    setTimeout(() => {
      setError(false)
    }, 2000)
  }

  const handleSubscribeEmail = (email) => {
    let mailChimpUrl = process.env.GATSBY_MAILCHIMP_URL

    fetch(mailChimpUrl, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },

      body: JSON.stringify({ email })
    })
      .then(() => {
        swal(
          'Thank you!',
          'You have been successfully added to our mailing list.',
          'success'
        )
        setSubscribed(true)
      })
      .catch((e) => {
        swal('Oops!', 'Something went wrong!', 'error')
        hmm()
      })
      .catch(() => {
        hmm()
      })
  }

  return (
    <>
      {subscribed ? (
        <> </>
      ) : (
        <div className="join__updates">
          <Container className="join____updatscontainer">
            <div className="SignUp__image">
              <div>
                <SignUpparagraph
                  paragraphText={description}
                  textColorClas={'white'}
                />
              </div>

              {loading ? (
                <p></p>
              ) : (
                <form
                  id="subscribe"
                  className={` ${error ? 'error' : ''}`}
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault()
                    setLoading(true)
                    const mailToSubscribe = e.target.email.value
                    if (isValidEmail.test(mailToSubscribe)) {
                      handleSubscribeEmail(mailToSubscribe)
                    } else {
                      hmm()
                    }
                  }}
                >
                  <div className={`main___button`}>
                    <div className="email_icon">
                      <img src={mailIcon} />
                      <input
                        type="email"
                        className={`email__tabs`}
                        placeholder="Enter your email"
                        name="email"
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="input__field"
                      disabled={loading}
                    >
                      {secondaryButton.buttonText}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </Container>
        </div>
      )}
    </>
  )
}

export default SubscribeSection
